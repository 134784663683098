<template>
  <section class="flex flex-col">
    <div class="flex justify-center items-center border-dotted border-2 border-[#3e3e3f] rounded-lg min-h-[200px] p-4 mb-2">
      <transition name="fade" mode="out-in">
        <Spinner v-if="isLoading" class="w-full"/>
        <p v-else-if="!hasAllRequiredInputs">Correct inputs are required to verify results</p>
        <p v-else-if="!gameResult">No game result</p>
        <InstantGameResultLimbo
          v-else-if="game.id === 'limbo'"
          :result="gameResult"
        />
        <InstantGameResultDice
          v-else-if="game.id === 'dice'"
          :result="gameResult"
        />
        <InstantGameResultMines
          v-else-if="game.id === 'mines'"
          :result="gameResult"
          :mines="minesNumber"
        />
        <InstantGameResultDiamonds
          v-else-if="game.id === 'diamonds'"
          :result="gameResult"
        />
        <InstantGameResultKeno
          v-else-if="game.id === 'keno'"
          :result="gameResult"
        />
        <InstantGameResultDragonTower
          v-else-if="game.id === 'dragon-tower'"
          :result="gameResult"
          :difficulty="dragonDifficulty"
        />
        <InstantGameResultPlinko
          v-else-if="game.id.startsWith('plinko')"
          :result="gameResult"
          :risk="plinkoRisk"
          :rows="plinkoRows"
          :game-id="game.id"
        />
        <InstantGameResultFrogCrossing
          v-else-if="game.id === 'frog-crossing'"
          :result="gameResult"
          :risk="frogCrossingRisk"
          :stop-index="frogCrossingStopIndex"
        />
      </transition>
    </div>
    <FormsFieldWithLabel label="Game">
      <select
        id="game"
        v-model="game"
        class="flex w-full text-xs items-center px-4 py-2 h-11 bg-slate-600 rounded-lg font-bold border-0"
        @change="verifyFairness"
      >
        <option
          v-for="gameOption in activeGames"
          :key="gameOption.id"
          :value="gameOption"
        >
          {{ gameOption.name }}
        </option>
      </select>
    </FormsFieldWithLabel>
    <FormsFieldWithLabel
      v-model="clientSeed"
      label="Client Seed"
      type="text"
      @update:model-value="debounceVerifyFairness"
    />
    <FormsFieldWithLabel
      v-model="serverSeed"
      label="Server Seed"
      type="text"
      @update:model-value="debounceVerifyFairness"
    />
    <FormsFieldWithLabel label="Nonce">
      <div class="flex flex-row bg-slate-600 rounded-lg">
        <input
          id="nonce"
          v-model="nonce"
          class="w-11/12 text-xs border-0 py-2 pb-2.5 h-11 rounded-lg bg-slate-600"
          type="number"
          @input="debounceVerifyFairness"
        >
        <button
          class="w-14 h-11 mr-0.5 bg-slate-900"
          type="button"
          @click.prevent.stop="handleBetAmount('down')"
        >
          <SvgIcon
            icon="arrow-down"
            width="24"
            height="24"
            class="my-auto"
          />
        </button>
        <button
          class="w-14 h-11 bg-slate-900 rounded-r-lg"
          type="button"
          @click.prevent.stop="handleBetAmount('up')"
        >
        <SvgIcon
          icon="arrow-down"
          width="24"
          height="24"
          class="my-auto rotate-180"
        />
        </button>
      </div>
    </FormsFieldWithLabel>
    <FormsFieldWithLabel
      v-if="game.id === 'mines'"
      label="Mines"
    >
      <select
        id="mines"
        v-model="minesNumber"
        class="flex w-full text-xs items-center px-4 py-2 h-11 bg-slate-600 rounded-lg font-bold border-0"
      >
        <option v-for="n in 24" :key="n" :value="n">{{ n }}</option>
      </select>
    </FormsFieldWithLabel>
    <FormsFieldWithLabel
      v-if="game.id === 'dragon-tower'"
      label="Difficulty"
    >
      <select
        id="difficulty"
        v-model="dragonDifficulty"
        class="flex w-full text-xs items-center px-4 py-2 h-11 bg-slate-600 rounded-lg font-bold border-0 capitalize"
        @update:model-value="debounceVerifyFairness"
      >
        <option
          v-for="difficultyLevel in Object.keys(dragonTowerRowConfigs)"
          :key="difficultyLevel"
          :value="difficultyLevel"
        >
          {{ difficultyLevel }}
        </option>
      </select>
    </FormsFieldWithLabel>
    <template v-if="game.id === 'frog-crossing'">
      <FormsFieldWithLabel label="Risk">
        <select
          id="risk"
          v-model="frogCrossingRisk"
          class="flex w-full text-xs items-center px-4 py-2 h-11 bg-slate-600 rounded-lg font-bold border-0 capitalize"
          @update:model-value="handleFrogCrossingRisk"
        >
          <option
            v-for="riskLevel in Object.keys(frogCrossingConfig)"
            :key="riskLevel"
            :value="riskLevel"
          >
            {{ riskLevel }}
          </option>
        </select>
      </FormsFieldWithLabel>
      <FormsFieldWithLabel label="Stop Index">
        <select
          id="stopIndex"
          v-model="frogCrossingStopIndex"
          class="flex w-full text-xs items-center px-4 py-2 h-11 bg-slate-600 rounded-lg font-bold border-0"
          @update:model-value="debounceVerifyFairness"
        >
          <option
            v-for="stop in frogCrossingConfig[frogCrossingRisk].stopIndex"
            :key="stop"
            :value="stop"
          >
            {{ stop }}
          </option>
        </select>
      </FormsFieldWithLabel>
    </template>
    <template v-if="game.id.startsWith('plinko')">
      <FormsFieldWithLabel label="Risk">
        <select
          id="risk"
          v-model="plinkoRisk"
          class="flex w-full text-xs items-center px-4 py-2 h-11 bg-slate-600 rounded-lg font-bold border-0"
        >
          <option value="low">Low</option>
          <option value="medium">Medium</option>
          <option value="high">High</option>
        </select>
      </FormsFieldWithLabel>
      <FormsFieldWithLabel label="Rows">
        <select
          id="rows"
          v-model="plinkoRows"
          class="flex w-full text-xs items-center px-4 py-2 h-11 bg-slate-600 rounded-lg font-bold border-0"
        >
          <option v-for="n in [8,9,10,11,12,13,14,15,16]" :key="n" :value="n">{{ n }}</option>
        </select>
      </FormsFieldWithLabel>
    </template>
  </section>
</template>

<script setup>
import { useDebounceFn } from '@vueuse/core';

const fairnessStore = useFairnessVerificationStore();
const { proveFairness, } = fairnessStore;
const { activeGames, mines, rows, risk, difficulty, stopIndex, rawSeedData, } = storeToRefs(fairnessStore);

const gameStore = useGamesStore();
const { gameFairnessData, } = storeToRefs(gameStore);

const isLoading = ref(false);
const clientSeed = ref('');
const serverSeed = ref('');
const nonce = ref(0);
const game = ref();
const gameResult = ref(null);
const minesNumber = ref(1);
const plinkoRisk = ref('low');
const plinkoRows = ref(8);
const dragonDifficulty = ref(Object.keys(dragonTowerRowConfigs)[0]);
const frogCrossingRisk = ref(Object.keys(frogCrossingConfig)[0]);
const frogCrossingStopIndex = ref(10);

function initialGame() {
  const gameFairnessName = gameFairnessData.value?.name;
  const firstGame = activeGames.value[0];
  game.value = gameFairnessName ? activeGames.value.find(game => game.name.toLowerCase() === gameFairnessName.toLowerCase()) || firstGame : firstGame;
}

async function verifyFairness() {
  if (!hasAllRequiredInputs.value) {
    return;
  }
  try {
    isLoading.value = true;
    const payload = {
      game: game.value.id,
      serverSeed: serverSeed.value,
      clientSeed: clientSeed.value,
      nonce: nonce.value,
    };
    if (game.value.id === 'frog-crossing') {
      payload.provider = 'metawin';
      const data = {
        difficulty: frogCrossingRisk.value,
        stopIndex: frogCrossingStopIndex.value,
      };
      payload.data = encodeURIComponent(JSON.stringify(data));
    }
    const response = await proveFairness(payload);
    gameResult.value = response;
  } finally {
    isLoading.value = false;
  }
}

const debounceVerifyFairness = useDebounceFn(verifyFairness, 250, { maxWait: 2000, });

function handleBetAmount(type) {
  if (type === 'up') {
    nonce.value++;
  } else if (type === 'down') {
    nonce.value--;
  }
  debounceVerifyFairness();
}

function handleFrogCrossingRisk() {
  frogCrossingStopIndex.value = frogCrossingStopIndex.value > frogCrossingConfig[frogCrossingRisk.value].stopIndex ? 1 : frogCrossingStopIndex.value;
  debounceVerifyFairness();
}

const hasAllRequiredInputs = computed(() => clientSeed.value && serverSeed.value && typeof nonce.value === 'number' && game.value);

onMounted(() => {
  clientSeed.value = rawSeedData.value.clientSeed || '';
  serverSeed.value = rawSeedData.value.serverSeed || '';
  nonce.value = rawSeedData.value.nonce || 0;
  minesNumber.value = mines.value;
  dragonDifficulty.value = difficulty.value;
  frogCrossingRisk.value = difficulty.value;
  frogCrossingStopIndex.value = stopIndex.value;
  plinkoRisk.value = risk.value;
  plinkoRows.value = rows.value;
  initialGame();
  verifyFairness();
});
</script>
